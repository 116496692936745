import { EntityState, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import * as fromActions from './notification.actions';
import { CanalTokenType, Notification } from '@libs/entity-lib';

export interface NotificationState extends EntityState<Notification> {
  // Outras propriedades de estado que você pode precisar adicionar
  error: any | null;
  loading: boolean;
  tokenWeb: string | null;
  tokenMobile: string | null;
}

export const adapter = createEntityAdapter<Notification>();

export const initialState: NotificationState = adapter.getInitialState({
  error: null,
  loading: false,
  tokenWeb: null,
  tokenMobile: null
});

export const reducer = createReducer(
  initialState,
  on(fromActions.requestPermissionsWithWeb, (state) => ({ ...state, loading: true, error: null })),
  
  on(fromActions.fetchAllNotifications, (state) => ({ ...state, loading: true, error: null })),
  on(fromActions.fetchAllNotificationsSuccessfully, (state, { notificationList }) => {
    return adapter.addMany(notificationList, {...state , loading: false });
  }),
  on(fromActions.fetchAllNotificationsFailed, (state, { error }) => ({ ...state, loading: false, error })),

  on(fromActions.readAllNotificationsSuccessfully, (state) => {
    return {
      ...adapter.removeAll(state),
      loading: false
    };
  }),
  on(fromActions.addNotification, (state, { notification }) => adapter.addOne(notification, state)),
  
  on(fromActions.registerToken, (state) => ({ ...state, loading: true, error: null })),
  on(fromActions.fetchAllNotificationsFailed, (state, { error }) => ({...state, error, loading: false })),
  
  on(fromActions.registerTokenMobileSuccess, (state, { token }) => ({...state, tokenMobile: token })),
  on(fromActions.registerTokenWebSuccess, (state, { token }) => ({...state, tokenWeb: token })),
  on(fromActions.requestPermissionsWithMobileFailed, (state, { error }) => ({...state, error, loading: false })),

  on(fromActions.resetTokenSuccessfully, (state, { canalTokenType, token }) => {
    switch(canalTokenType) {
      case CanalTokenType.WEB:
        return {...state, tokenWeb: token };
      case CanalTokenType.MOBILE:
        return {...state, tokenMobile: token };
      default:
        return state;
    }
  }),
  on(fromActions.fetchNotificationTokens, (state) => ({...state, loading: true, error: null })),
  on(fromActions.fetchNotificationTokenSuccessfully, (state, { notificationToken }) => ({...state, loading: false, tokenWeb: notificationToken.tokenNotificationWeb, tokenMobile: notificationToken.tokenNotificationMobile })),
  on(fromActions.fetchNotificationTokenFailed, (state, { error }) => ({...state, loading: false, error })),
  
  on(fromActions.readNotification, (state) => ({...state, loading: true, error: null })),
  on(fromActions.removeNotificationById, (state, { id }) => ({
    ...adapter.removeOne(id, state),
    loading: false
  })),
  on(fromActions.readNotificationsFailed, (state, { error }) => ({...state, loading: false, error })),
);

export const { selectAll } = adapter.getSelectors();